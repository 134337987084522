<template>
    <span v-if="hide">
        <v-navigation-drawer
            v-model="isOpen"
            :temporary="true"
            app
            overflow
            style="min-width: 285px;"
        >
            <v-list dense>
                <v-list-item link to=""> <!-- /account -->
                    <div class="ma-2">
                        <img
                            :src="require('@/assets/zaper-menu-icon.png')"
                            style="width: 35px; height: 35px;"
                            alt="Minha Conta"
                        >
                    </div>
                    <v-list-item-content>
                        <v-list-item-title>{{ getUserFirstName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ usuario.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider dark class="my-4"/>

                <v-list-item v-if="showOffer()" link to="/plano-anual" class="offer pulse">
                    <v-list-item-action>
                        <v-icon color="white">mdi-sale</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white; font-weight: bold;">
                            <!-- Desconto no Plano Anual -->
                            Black November
                        </v-list-item-title>
                        <div style="display: flex; color: white;">
                            <span class="offer-expiration white--text">
                                {{ expireAtLabel }}
                            </span>
                        </div>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="false" class="indicacao" @click="showIndique = true">
                    <v-list-item-action>
                        <v-icon color="white">mdi-gift-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white;">
                            Indique e Ganhe!
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="false">
                    <v-list-item-content>
                        <v-text-field
                            label="Pesquise"
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            outlined
                            dense
                            hide-details
                        />
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/">
                    <v-list-item-action>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Inicio</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="showInstallPwa" @click="installPwa">
                    <v-list-item-action>
                        <v-icon>mdi-download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Instalar Aplicativo</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="usuario.tipo == 'suporte'" link to="/register">
                    <v-list-item-action>
                        <v-icon>mdi-domain</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Nova Empresa</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-action>
                            <v-icon>mdi-file-document-edit</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Cadastros</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <div style="margin-left: 20px;">
                        <v-list-item link to="/produtos">
                            <v-list-item-action>
                                <v-icon>mdi-hamburger</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Produtos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link to="/combos">
                            <v-list-item-action>
                                <v-icon>mdi-food</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Combos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="isAdmin" link to="/grupos">
                            <v-list-item-action>
                                <v-icon>mdi-tag</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Grupos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link to="/complementos">
                            <v-list-item-action>
                                <v-icon>mdi-view-grid-plus</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Complementos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="isAdmin" link to="/complementos-categorias">
                            <v-list-item-action>
                                <v-icon>mdi-format-list-bulleted</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Categorias de Complementos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="isAdmin" link to="/formas-pagamento">
                            <v-list-item-action>
                                <v-icon>mdi-currency-usd</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Formas de Pagamento</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="isAdmin" link to="/entregadores">
                            <v-list-item-action>
                                <v-icon>mdi-bike-fast</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Entregadores</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="hasFiscalModule && isAdmin" link to="/categorias-imposto">
                            <v-list-item-action>
                                <v-icon>mdi-percent-circle-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Categorias de Imposto</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="hasFiscalModule && isAdmin" link to="/unidades-medida">
                            <v-list-item-action>
                                <v-icon>mdi-shape-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Unidades de Medida</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="isSuporte" link to="/cadastro-rapido-produtos">
                            <v-list-item-action>
                                <v-icon>mdi-lightning-bolt</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Cad. Rápido de Produtos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="isSuporte" link to="/cadastro-rapido-complementos">
                            <v-list-item-action>
                                <v-icon>mdi-lightning-bolt</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Cad. Rápido de Complementos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list-group>

                <CaixaMenuListGroup />

                <v-list-item link to="/clientes" v-if="isAdmin">
                    <v-list-item-action>
                        <v-icon>mdi-account-heart</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Clientes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/colaboradores" v-if="isAdmin">
                    <v-list-item-action>
                        <v-icon>mdi-account-tie</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Colaboradores</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/cupons" v-if="isAdmin">
                    <v-list-item-action>
                        <v-icon>mdi-sale</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Cupons</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group v-if="isAdmin">
                    <template v-slot:activator>
                        <v-list-item-action>
                            <v-icon>mdi-bike</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Taxas de Entrega</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <div style="margin-left: 20px;">
                        <v-list-item link to="/municipios">
                            <v-list-item-action>
                                <v-icon>mdi-map-search</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Municipios</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link to="/bairros">
                            <v-list-item-action>
                                <v-icon>mdi-map</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Bairros</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link to="/distancias">
                            <v-list-item-action>
                                <v-icon>mdi-map-marker-radius</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Distância</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list-group>

                <!-- <v-list-item link to="/contas-pagar-receber" v-if="isAdmin">
                    <v-list-item-action>
                        <v-icon>mdi-finance</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Contas a Pagar/Receber</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <v-list-group v-if="isAdmin">
                    <template v-slot:activator>
                        <v-list-item-action>
                            <v-icon>mdi-file-chart</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Relatórios</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <div style="margin-left: 20px;">
                        <v-list-item link to="/relatorio-vendas">
                            <v-list-item-action>
                                <v-icon>mdi-chart-areaspline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Vendas</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link to="/relatorio-vendas-produtos">
                            <v-list-item-action>
                                <v-icon>mdi-chart-areaspline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Produtos Vendidos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link to="/relatorio-estoque">
                            <v-list-item-action>
                                <v-icon>mdi-chart-areaspline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Estoque</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link to="/relatorio-graficos">
                            <v-list-item-action>
                                <v-icon>mdi-chart-pie</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Gráficos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list-group>

                <v-list-item @click="goToNotasFiscais">
                    <v-list-item-action>
                        <v-icon>mdi-text-box-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div v-if="!hasFiscalModule">
                                <v-chip color="red darken-4 white--text" x-small>
                                    Assine já
                                </v-chip>
                                <br>
                            </div>
                            Notas Fiscais
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="!hasModule('PAGAMENTO_ONLINE')" @click="goToPagamentoOnline">
                    <v-list-item-action>
                        <v-icon>mdi-credit-card-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div>
                                <v-chip color="red darken-4 white--text" x-small>
                                    Assine já
                                </v-chip>
                                <br>
                            </div>
                            <!-- Pagamento Online -->
                            Pix Automatizado
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isAdmin" @click="goToFidelidade">
                    <v-list-item-action>
                        <v-icon>mdi-gift-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div v-if="!hasModule('FIDELIDADE')">
                                <v-chip color="red darken-4 white--text" x-small>
                                    Assine já
                                </v-chip>
                                <br>
                            </div>
                            Programa de Fidelidade
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isAdmin" @click="goToCampanhas">
                    <v-list-item-action>
                        <v-icon>mdi-robot</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div v-if="!hasModule('CAMPANHAS')">
                                <v-chip color="red darken-4 white--text" x-small>
                                    Assine já
                                </v-chip>
                                <br>
                            </div>
                            Campanhas
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isAdmin && hasModule('CAMPANHAS')" link to="/publicos">
                    <v-list-item-action>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Públicos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/configuracoes" v-if="isAdmin">
                    <v-list-item-action>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Configurações</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider dark class="my-4"/>

                <v-list-item @click="showLinkCardapio = true">
                    <v-list-item-action>
                        <v-icon>mdi-open-in-new</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Meu Cardápio Digital</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/qrcode">
                    <v-list-item-action>
                        <v-icon>mdi-qrcode</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>QR Code</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider dark class="my-4"/>

                <v-list-item link to="/minha-assinatura" v-if="isAdmin">
                    <v-list-item-action>
                        <v-icon>mdi-rocket-launch-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Minha Assinatura</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="showIndique = true">
                    <v-list-item-action>
                        <v-icon>mdi-heart</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Indique e Ganhe</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider dark class="my-4"/>

                <!-- <v-list-item @click="goToDocs">
                    <v-list-item-action>
                        <v-icon>mdi-help-circle-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Documentação</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <v-list-item link to="/videos-de-ajuda">
                    <v-list-item-action>
                        <v-icon>mdi-youtube</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Videos de Ajuda</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/novidades">
                    <v-list-item-action>
                        <v-icon>mdi-bullhorn-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Novidades</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="goToSuporte">
                    <v-list-item-action>
                        <v-icon>mdi-whatsapp</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Suporte</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider dark class="my-4"/>

                <v-list-item @click="logout">
                    <v-list-item-action>
                        <v-icon color="red">mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app clipped-right>
            <span class="menu-label">MENU</span>
            <v-app-bar-nav-icon @click.stop="isOpen = !isOpen"/>
            <v-spacer />
            <div>
                <v-autocomplete
                    v-if="isSuporte"
                    v-model="empresa"
                    @change="onSelect"
                    @keypress.enter="searchEmpresas"
                    @update:search-input="searchInput = $event"
                    :items="empresas"
                    :item-text="e => `${e.id} - ${e.fantasia}`"
                    :debounce-search="0"
                    item-value="id"
                    label="Empresa"
                    prepend-inner-icon="mdi-magnify"
                    dense
                    x-small
                    outlined
                    no-filter
                    hide-details
                    hide-selected
                />
                <div v-else @click="goToHome" style="cursor: pointer;">
                    <v-icon>mdi-home-city</v-icon>
                    <span class="ml-1">{{ usuario.empresa }}</span>
                </div>
            </div>
            <v-spacer />

            <div v-if="showFatura">
                <span :class="`text-subtitle-2 ${getFaturaColor}--text mr-2`">
                    {{ getFaturaText }}
                </span>
                <v-btn @click="verBoleto" color="primary" small>
                    Ver boleto
                </v-btn>
            </div>

            <div
                v-show="!showFatura && $vuetify.breakpoint.smAndUp"
                @click="goToColaboradores"
                style="cursor: pointer;"
            >
                <v-icon>mdi-account</v-icon>
                <span>{{ getUserFirstName }}</span>
            </div>

            <v-spacer />
            <div v-if="$route.path == '/'">
                <OpeningHoursMenu />
            </div>
            <div v-if="$route.path == '/'">
                <TimeChangeMenu />
            </div>
            <div v-if="$route.path == '/'">
                <QrCodeWhatsapp />
            </div>
            <div v-if="$route.path == '/'">
                <Notifications />
            </div>
        </v-app-bar>
        <IndiqueDialog
            :visible="showIndique"
            @close="showIndique = false"
        />
        <LinkCardapioDialog
            :visible="showLinkCardapio"
            @close="showLinkCardapio = false"
        />
    </span>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { hasModule } from '@/utils/module';
import Notifications from './Notifications.vue';
import QrCodeWhatsapp from './QrCodeWhatsapp.vue';
import TimeChangeMenu from './TimeChangeMenu.vue';
import OpeningHoursMenu from './OpeningHoursMenu.vue';
import IndiqueDialog from '../IndiqueDialog.vue';
import LinkCardapioDialog from '../LinkCardapioDialog.vue';
import CaixaMenuListGroup from './Items/CaixaMenuListGroup.vue';

export default {
    name: 'Menu',

    components: {
        Notifications,
        QrCodeWhatsapp,
        TimeChangeMenu,
        OpeningHoursMenu,
        IndiqueDialog,
        LinkCardapioDialog,
        CaixaMenuListGroup,
    },

    props: ['hide'],

    data: () => ({
        isOpen: false,
        empresa: {},
        searchInput: '',
        showInstallPwa: false,
        showIndique: false,
        showLinkCardapio: false,
    }),

    computed: {
        ...mapState([
            'usuario',
            'empresas',
            'configuracoes',
            'lastNps',
            'ultimaFatura',
            'assinatura',
        ]),

        isAdmin() {
            return this.usuario.admin;
        },

        isSuporte() {
            return this.usuario.tipo === 'suporte';
        },

        hasFiscalModule() {
            return hasModule('FISCAL');
        },

        getUserFirstName() {
            return this.usuario.name ? this.usuario.name.split(' ')[0] : '';
        },

        faturaDisponivel() {
            return this.ultimaFatura?.status === 'waiting';
        },

        faturaVencida() {
            return this.ultimaFatura?.status === 'unpaid';
        },

        faturaVenceHoje() {
            if (['paid', 'settled', 'identified'].includes(this.ultimaFatura?.status)) {
                return false;
            }
            const currentDate = this.moment().format('YYYY-MM-DD');
            return this.ultimaFatura?.payment?.banking_billet?.expire_at === currentDate;
        },

        faturaVenceEm10DiasOuMenos() {
            const currentDate = this.moment().startOf('day'); 
            const expireDate = this.moment(this.ultimaFatura?.payment?.banking_billet?.expire_at).startOf('day'); 

            const diffDays = expireDate.diff(currentDate, 'days');

            return diffDays <= 10;
        },

        getFaturaText() {
            const expireAt = this.moment(this.ultimaFatura?.payment?.banking_billet?.expire_at).format('DD/MM');
            if (this.faturaVencida) {
                return `Fatura vencida (${expireAt})`;
            }
            if (this.faturaVenceHoje) {
                return 'Fatura vence hoje';
            }

            return `Fatura disponível (${expireAt})`;
        },

        getFaturaColor() {
            if (this.faturaVencida) {
                return 'error';
            }
            if (this.faturaVenceHoje) {
                return 'warning';
            }

            return 'primary';
        },

        showFatura() {
            const createdAt = this.moment(this.configuracoes.created_at);
            const now = this.moment();
            const diffInDays = now.diff(createdAt, 'days');
            const empIsOlderThanEightDays = diffInDays > 8;

            const pendencia = this.faturaVencida || this.faturaDisponivel || this.faturaVenceHoje;

            return empIsOlderThanEightDays 
                && this.faturaVenceEm10DiasOuMenos 
                && pendencia 
                && this.$vuetify.breakpoint.mdAndUp
                && this.$route.path == '/';
        },

        expireAtLabel() {
            const daysUntilExpire = this.daysUntilExpire();
            if (daysUntilExpire === 0) {
                return 'Termina hoje';
            }
            return `Termina em ${daysUntilExpire} ${daysUntilExpire > 1 ? 'dias' : 'dia'}`;
        },
    },

    mounted() {
        this.setEmpresa();
        document.addEventListener('menu-install-pwa', () => (this.showInstallPwa = true));
    },

    watch: {
        usuario() {
            this.setEmpresa();
        },
    },

    methods: {
        ...mapMutations({
            setUsuario: 'setUsuario',
            setEmpresas: 'setEmpresas',
        }),

        hasModule(module) {
            return hasModule(module);
        },

        installPwa() {
            document.dispatchEvent(new Event('click-install-pwa'));
        },

        setEmpresa() {
            if (!this.usuario.empresa || !this.isSuporte) {
                return;
            }

            const { empresa_id: id, empresa: fantasia} = this.usuario;
            this.empresa = { id, fantasia };

            const find = this.empresas.find(e => e.id === id);
            !find && this.empresas.push(this.empresa);
        },

        logout() {
            if (confirm('Deseja realmente sair?')) {
                this.$http.post('logout').then(() => {
                    sessionStorage.clear();
                    this.setUsuario({});
                    this.$router.push('/login');
                }).catch(() => this.notify('Verifique sua conexão com a internet', 'warning'));
            }
        },

        goToSuporte() {
            const msg = encodeURIComponent('Olá, preciso de ajuda com o ZaperMenu');
            this.goToWhatsapp(msg);
        },

        goToHome() {
            if (this.$route.path == '/') {
                this.$root.$emit('listarComandas');
            } else {
                this.$router.push('/');
            }
        },

        goToNotasFiscais() {
            const route = this.hasModule('FISCAL') ? 'notas-fiscais' : 'modulo-fiscal';
            this.$router.push(route);
        },

        goToPagamentoOnline() {
            if (this.$route.path === '/plano-profissional') {
                this.isOpen = false;
                return;
            }
            this.$router.push('plano-profissional');
        },

        goToFidelidade() {
            if (this.$route.path === '/plano-profissional') {
                this.isOpen = false;
                return;
            }
            const route = this.hasModule('FIDELIDADE') ? 'pontos-movimentacoes' : 'plano-profissional';
            this.$router.push(route);
        },

        goToCampanhas() {
            if (this.$route.path === '/plano-profissional') {
                this.isOpen = false;
                return;
            }
            const route = this.hasModule('CAMPANHAS') ? 'campanhas' : 'plano-profissional';
            this.$router.push(route);
        },

        goToColaboradores() {
            this.isAdmin && (this.$route.path != '/colaboradores') && this.$router.push('/colaboradores');
        },

        goToDocs() {
           window.open('https://admin.zapermenu.com.br/docs');
        },

        goToWhatsapp(msg) {
            const isMobile = !this.$vuetify.breakpoint.smAndUp;

            const link = isMobile ? 'whatsapp://send' : 'https://api.whatsapp.com/send';
            const phone = '5549988951210';

            window.open(`${link}?phone=${phone}&text=${msg}`);
        },

        async searchEmpresas() {
            try {
                const { data } = await this.$http.get(`empresa?q=${this.searchInput || ''}`);
                this.setEmpresas(data.data)
            } catch (e) {
                console.log(e);
            }
        },

        async onSelect(empId) {
            if (!empId) {
                return;
            }

            const params = { ...this.usuario, ...{empresa_id: empId} };

            try {
                this.$root.$emit('loading', true);
                await this.$http.put(`usuarios/${this.usuario.id}`, params);

                location.reload();
            } catch (e) {
                console.log(e);
            }
        },

        showOffer() {
            const expireAt = this.moment('2024-11-30').startOf('day');
            const now = this.moment().startOf('day');
            const isExpired = now.isAfter(expireAt);
            // const createdAt = this.moment(this.configuracoes?.created_at);
            // const companyAgeInMonths = now.diff(createdAt, 'months');
            // const isNewCompany = companyAgeInMonths < 3;
            // const fiveStars = this.lastNps?.stars === 5;

            return !isExpired && this.assinatura?.periodicidade === 'mensal'; // && fiveStars;
        },

        daysUntilExpire() {
            const expireAt = this.moment('2024-11-30').startOf('day');
            const now = this.moment().startOf('day');
            return expireAt.diff(now, 'days');
        },

        verBoleto() {
            const url = this.ultimaFatura?.payment?.banking_billet?.link;
            window.open(url);
        },
    },
};
</script>

<style scoped>
.offer {
    /* border: 1px dashed #2bff5b; */
    border: 1px dashed #000;
    margin: 0 15px 10px 5px;
    border-radius: 15px;
    /* background: linear-gradient(to right, #52bf54, #81d98f); */
    background: linear-gradient(to right, #000, #241b1b);
}

.offer-expiration {
    font-size: 10px;
    font-weight: 500;
}

.indicacao {
    border: 1px dashed #fff;
    margin: 0 15px 10px 5px;
    border-radius: 15px;
    background: linear-gradient(to right, #931515, #ff2525);
}

.menu-label {
    position: absolute;
    font-size: 10px;
    top: 5px;
    left: 26px;
}

@keyframes pulse {
   0% {
		transform: scale(0.97);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.97);
	}
}

.pulse {
    animation: pulse 2s infinite;
}
</style>