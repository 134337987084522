<template>
   <v-dialog v-model="visible" persistent max-width="500px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Indique e Ganhe!</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                style="height: 280px;"
            >
                <div class="mb-6 mt-4">
                    <v-alert
                        outlined
                        prominent
                        class="mt-4"
                        icon="mdi-gift-outline"
                    >
                        <b>A cada indicação bem sucedida você ganha um pix no valor R$50,00!</b>
                    </v-alert>
                </div>
                <div class="mb-6 mt-4">Envie para seus amigos e parceiros de negócio 👇🏻</div>
                <v-btn @click="copyLink" color="primary" block>
                    <v-icon>mdi-content-copy</v-icon>
                    Copiar link de indicação
                </v-btn>
                <v-btn @click="shareOnWhatsapp" color="success" class="mt-4" block>
                    <v-icon>mdi-whatsapp</v-icon>
                    Compartilhar no whatsapp
                </v-btn>

                <a
                    ref="whatsappShare"
                    :href="getShareOnWhatsapp"
                    data-action="share/whatsapp/share"
                />
            </v-card-text>
        </v-card>
        <textarea style="position: absolute; left: 9999px;" id="transferArea" readonly />
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'IndiqueDialog',

    props: ['visible'],

    computed: {
        ...mapState([
            'usuario',
        ]),

        getMensagemIndicacao() {
            const txtIndicacao = `Olá, vim pela indicação de ${this.usuario.dominio}, gostaria de saber mais sobre o ZaperMenu`;
            const linkIndicacao = `https://api.whatsapp.com/send?phone=5549988951210&text=${encodeURIComponent(txtIndicacao)}`;
            const text = `Olá, passando aqui para te indicar uma plataforma que estou usando e está me ajudando muito, Cardápio Digital do ZaperMenu`
                + `\n\nClica nesse link aqui para conhecer 👇🏻 \n\n${linkIndicacao}`;
            return text;
        },

        getShareOnWhatsapp() {
            const text = encodeURIComponent(`Olá, passando aqui para te indicar uma plataforma que estou usando e está me ajudando muito, Cardápio Digital do ZaperMenu`
                + `\n\nClica nesse link aqui para conhecer 👇🏻 \n\n https://api.whatsapp.com/send?phone=5549988951210`);
            return `whatsapp://send?text=${text}`;
        },
    },

    methods: {
        copyLink() {
            const transferArea = document.querySelector('#transferArea');
            transferArea.value = this.getMensagemIndicacao;
            transferArea.select();

            document.execCommand('copy');
            this.notify('Link copiado!');

            window.getSelection().removeAllRanges();
        },

        shareOnWhatsapp() {
            this.$refs.whatsappShare.click();
        },
    },
}
</script>
