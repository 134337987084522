<template>
    <v-menu offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-clock-time-eight-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Alterar tempo de entrega / retirada</v-card-title>
            <v-card-text class="pb-4">
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <div>
                            <v-icon>mdi-clock-outline</v-icon>
                            Tempo de <b>entrega</b>
                        </div>
                        <v-range-slider
                            v-model="tempo_entrega"
                            @change="onTimeChange('tempo_entrega')"
                            min="0"
                            max="180"
                            step="5"
                            hide-details
                        />
                        <h3>{{ formatTimeString(tempo_entrega[0]) }} à {{ formatTimeString(tempo_entrega[1]) }}</h3>
                    </v-col>
                </v-row>
                <v-divider class="my-6"/>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <div>
                            <v-icon>mdi-clock-outline</v-icon>
                            Tempo de <b>retirada</b>
                        </div>
                        <v-range-slider
                            v-model="tempo_retirada"
                            @change="onTimeChange('tempo_retirada')"
                            min="0"
                            max="180"
                            step="5"
                            hide-details
                        />
                        <h3>{{ formatTimeString(tempo_retirada[0]) }} à {{ formatTimeString(tempo_retirada[1]) }}</h3>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'TimeChangeMenu',

    computed: {
        ...mapState([
            'configuracoes',
        ]),

        tempo_entrega: {
            get() {
                return this.configuracoes.tempo_entrega?.split(' - ') || [20, 60];
            },

            set(v) {
                this.setConfig({ option: 'tempo_entrega', value: v.join(' - ') });
            },
        },

        tempo_retirada: {
            get() {
                return this.configuracoes.tempo_retirada?.split(' - ') || [20, 60];
            },

            set(v) {
                this.setConfig({ option: 'tempo_retirada', value: v.join(' - ') });
            },
        },
    },

    methods: {
        ...mapMutations({
            setConfig: 'setConfig',
        }),

        onTimeChange(option) {
            const data = { [option]: this[option].join(' - ') };
            this.$http.post('configuracoes/set-config', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.notify('Atualizado com sucesso!');
                })
                .catch(() => this.notify('Não foi possivel atualizar', 'warning'))
        },

        formatTimeString(min) {
            const hours = Math.floor(min / 60);
            const minutes = min % 60;

            if (hours > 0 && minutes > 0) {
                return `${hours}h${minutes}min`;
            } else if (hours > 0) {
                return `${hours}h`;
            } else {
                return `${minutes}min`;
            }
        },
    },
}
</script>

<style scoped>
</style>
