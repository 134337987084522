import store from '../store/index';

// const isSuportUser = () => {
//     return store.state.usuario.tipo === 'suporte';
// }

export const hasModule = (module) => {
    const modules = store.state.usuario.modulos?.map(m => m.key) || [];

    return modules.includes(module)// || isSuportUser();
};
