<template>
   <v-dialog v-model="visible" persistent max-width="500px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Compartilhe seu Cardápio Digital</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                style="height: 200px;"
            >

                <v-text-field
                    ref="link"
                    label="Link"
                    :value="getLink"
                    outlined
                    readonly
                    dense
                    class="mt-8"
                />
                
                <v-btn @click="copyLink" color="primary" block>
                    <v-icon>mdi-content-copy</v-icon>
                    Copiar link
                </v-btn>
                <v-btn @click="acessar" color="primary" class="mt-6" block text>
                    <v-icon>mdi-open-in-new</v-icon>
                    Acessar Cardápio
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'LinkCardapioDialog',

    props: ['visible'],

    computed: {
        ...mapState([
            'usuario',
        ]),

        getLink() {
            const baseURL = (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8081/'
                : 'https://app.zapermenu.com.br/';

            return baseURL + this.usuario.dominio;
        },
    },

    methods: {
        copyLink() {
            const transferArea = this.$refs.link.$el.querySelector('input');
            transferArea.select();

            document.execCommand('copy');
            this.notify('Link copiado!');

            window.getSelection().removeAllRanges();
        },

        acessar() {
            window.open(this.getLink);
        },
    },
}
</script>
