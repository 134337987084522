<template>
    <v-dialog :value="true" persistent max-width="600px">
        <v-card v-if="!showFeedbackResponse">
            <v-card-title class="justify-center">
                <v-spacer/>
                <span :class="isMobile ? 'text-h6' : 'text-h5'">Você gosta do ZaperMenu?</span>
                <v-spacer/>
                <v-btn v-show="false" icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <div>Por favor avalie-nos 😉👇🏻</div>
                <v-rating
                    v-model="form.stars"
                    background-color="grey"
                    color="warning"
                    hover
                    length="5"
                    :size="isMobile ? 30 : 50"
                    class="mt-2"
                />
                <v-textarea
                    v-show="form.stars"
                    ref="observacao"
                    v-model="form.feedback"
                    label="Deixe seu feedback, sugestão ou depoimento (opcional)"
                    rows="4"
                    clear-icon="mdi-backspace-outline"
                    clearable
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <!-- <v-checkbox
                    v-show="form.stars"
                    v-model="form.retorno"
                    label="Desejo receber um retorno"
                /> -->
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-show="false"
                    @click="$emit('close')"
                    color="warning"
                    text
                >Cancelar</v-btn>
                <v-spacer />
                <v-btn
                    @click="salvar"
                    :loading="loading"
                    :disabled="!form.stars"
                    color="success"
                    width="120"
                    large
                >
                    Enviar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-else class="justify-center pb-2">
            <v-card-title style="background: #4CAF50;">
                <v-spacer/>
                <v-icon size="35" color="white">mdi-check-circle-outline</v-icon>
                <span style="color: white;" class="ml-2">Obrigado!</span>
                <v-spacer/>
            </v-card-title>
            <v-card-text class="text-center">
                <div class="mt-4">{{ getFeedbackResponse }}</div>
            </v-card-text>
            <v-btn
                @click="close"
                color="primary"
                block
                text
            >
                Fechar
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'NpsDialog',

    data: () => ({
        loading: false,
        showFeedbackResponse: false,
        form: {
            stars: null,
            feedback: null,
            retorno: false,
        },
    }),

    computed: {
        isMobile() {
            return !this.$vuetify.breakpoint.smAndUp;
        },

        getFeedbackResponse() {
            if ([1, 2].includes(this.form.stars)) {
                return 'Lamentamos saber que sua experiência não foi satisfatória. Agradecemos pelo feedback e gostaríamos de entender melhor como podemos melhorar. Por favor, entre em contato conosco para que possamos resolver essa situação.';
            }
            if ([3, 4].includes(this.form.stars)) {
                return 'Agradecemos pela sua avaliação! Estamos sempre buscando formas de aperfeiçoar nosso serviço. Se houver algo que possamos fazer para transformar sua experiência em excelente, não hesite em nos informar.';
            }

            return 'Ficamos extremamente felizes em saber que você está satisfeito com o nosso serviço. Estamos sempre à disposição para atendê-lo da melhor forma.';
        },
    },

    methods: {
        ...mapMutations({
            setShowNps: 'setShowNps',
        }),

        salvar() {
            this.loading = true;
            this.$http.post('nps', this.form).then(() => {
                this.showFeedbackResponse = true;
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        close() {
            this.setShowNps(false);
        },
    },
}
</script>
