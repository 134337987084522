<template>
    <div style="display: flex; flex-direction: column; height: 100%; z-index: 99999; background-color: #ddd;">
        <div class="no-print" style="display: flex; justify-content: flex-end;">
            <v-progress-circular
                :value="progress"
                :size="52"
                color="indigo darken-2"
                class="no-print"
            >
                <v-btn
                    @click="close"
                    x-large
                    icon
                    right
                    depressed
                    style="z-index: 9;"
                    class="no-print"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-progress-circular>
        </div>

        <div style="width: 300px; margin: auto; background-color: #ffffc8; margin-top: 30px;">
            <div v-html="html" class="print" />
        </div>

        <div style="display: flex; justify-content: center;">
            <v-btn
                v-if="imprimirVisible"
                @click="imprimir"
                label="Imprimir"
                large
                color="#000"
                class="no-print white--text mb-4"
            >
                <v-icon color="#fff">mdi-printer</v-icon>&nbsp;
                <span class="ml-1">Imprimir</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Imprimir',

    props: {
        pedidoId: {
            type: Number,
        },

        tipo: {
            type: String,
        },
    },

    data: () => ({
        html: 'Aguarde, carregando...',
        pedido: null,
        imprimirVisible: false,
        timeout: null,
        interval: null,
        closeAt: null,
        progress: 100,
    }),

    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });

        if (this.configuracoes?.impressao?.servicoImpressao === 'USB') {
            window.onafterprint = () => {
                this.close();
            }
        }

        this.getHtmlPedido(this.pedidoId);
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.close);
        clearInterval(this.interval);
    },

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
        ]),

        getTimeLeft() {
            return Math.ceil((this.timeout._idleStart + this.timeout._idleTimeout - Date.now()) / 1000);
        },
    },

    methods: {
        async getHtmlPedido(pedidoId) {
            this.imprimirVisible = false;

            if (!pedidoId) {
                this.html = 'Aguarde, carregando...';
                return;
            }

            const { dominio: emp } = this.usuario;

            const params = `tipo=${this.tipo}`;

            await this.$http.get(`pedidos/imprimir/${emp}/${pedidoId}?${params}`).then(resp => {
                this.html = resp.data.data;
                this.imprimirVisible = true;

                setTimeout(() => this.imprimir(), 1000);
            });
        },

        setAutoCloseTimer() {
            const EIGHT_SECONDS_IN_MILLISECONDS = 8000;

            this.closeAt = this.moment().add(EIGHT_SECONDS_IN_MILLISECONDS, 'milliseconds');

            this.timeout = setTimeout(() => {
                this.close();
            }, EIGHT_SECONDS_IN_MILLISECONDS);

            this.interval = setInterval(() => {
                let diff = this.moment().diff(this.closeAt, 'milliseconds') / 1000;

                let percentage = diff * (1000 / (EIGHT_SECONDS_IN_MILLISECONDS / 100)) * -1;

                this.progress = percentage;
            }, 100);
        },

        imprimir() {
            window.print();
            this.setImpresso(this.pedidoId);
            this.setAutoCloseTimer();
        },

        setImpresso(id) {
            this.$http.post('pedidos/set-impresso', { id });
        },

        close() {
            this.$emit('close');
        },
    },
}
</script>

<style scoped>
@media print {
    /* body,  */
    .no-print {
        display: none;
        visibility: hidden;
    }

    .print {
        max-height: 200px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        z-index: 9999999;
    }
}
</style>
