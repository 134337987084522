<template>
    <v-app>
        <Menu
            v-show="!orderPrint.visible"
            :hide="!hideMenu"
            class="no-print"
        />
        <span
            v-show="!orderPrint.visible"
            class="no-print"
        >
            <div :style="'position: fixed; width: 100%; height: 100%; background-color: #ddd; opacity: 0.6; z-index: 9999999;'
                + (loading ? '' : 'display: none;')"
            >
                <v-img
                    width="200"
                    :src="require('@/assets/loading.gif')"
                    style="top: 30%; margin: auto; z-index: 999999;"
                />
            </div>

            <slot name="principal" />

            <v-snackbar
                v-model="snackbar.show"
                :timeout="2500"
                elevation="24"
                :color="snackbar.type === 'success' ? 'green accent-4' : 'error'"
            >
                <v-icon color="white">
                    mdi-{{ snackbar.type === 'success' ? 'check-circle-outline' : 'close-circle-outline' }}
                </v-icon>
                {{ snackbar.text }}
            </v-snackbar>
        </span>

        <Confirm ref="confirm" />

        <NpsDialog
            v-if="showNps"
        />

        <DialogImprimir
            v-if="orderPrint.visible"
            :tipo="orderPrint.tipo"
            :visible="orderPrint.visible"
            :pedidoId="orderPrint.pedidoId"
            @close="orderPrint = { visible: false }"
        />
    </v-app>
</template>

<script>
import Menu from '@/components/Menu/Menu.vue';
import Confirm from '@/components/Confirm.vue';
import NpsDialog from '@/components/NpsDialog.vue';
import DialogImprimir from '@/pages/imprimir/Index.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    name: 'BaseContainer',

    components: {
        Menu,
        Confirm,
        DialogImprimir,
        NpsDialog,
    },

    props: ['hideMenu'],

    data: () => ({
        loading: false,
        snackbar: { show: false, text: '', type: '' },
        orderPrint: {
            visible: false,
            pedidoId: null,
            autoPrint: null,
        },
    }),

    computed: {
        ...mapState([
            'usuario',
            'isDashboardInitialized',
            'configuracoes',
            'formasEntrega',
            'formasPagamento',
            'soundAlert',
            'showNps',
        ]),

        usuarioImpressao() {
            const user = this.usuario;
            return [null, user?.id].includes(this.configuracoes.usuario_impressao);
        },
    },

    async mounted() {
        this.$vuetify.theme.dark = false;

        await this.checkUser();
        this.setUpWs();
        await this.initializeDashboard();
        this.setListeners();
    },

    beforeDestroy() {
        this.removeListeners();
    },

    methods: {
        ...mapMutations({
            setUsuario: 'setUsuario',
            setUltimaFatura: 'setUltimaFatura',
        }),

        ...mapActions([
            'setUpWs',
            'setUpDashboardData',
            'sendPrintQueue',
        ]),

        setLoading(loading) {
            this.loading = loading;
        },

        setSnackbar(data) {
            data.show = true;
            this.snackbar = data;
        },

        showDialogImprimir(pedidoId, tipo) {
            this.orderPrint = {
                visible: true,
                pedidoId,
                tipo,
            };
        },

        async wsOrderEvent({ detail }) {
            if (detail.type === 'CREATE') {
                detail.fromApp && this.soundAlert.play();
                this.sendPrintQueue({ id: detail.orderId });
            }
        },

        autoPrint({ detail: pedido }) {
            const config = this.configuracoes.impressao;
            const aceiteManual = !this.configuracoes.confirmar_pedidos_automaticamente;
            const printByApp = this.configuracoes?.impressao?.servicoImpressao === 'App ZaperMenu';
            const impressaoManual = !config?.impressaoAutomatica;
            const usuarioNaoImpressor = !this.usuarioImpressao;

            // TODO: [AJUSTAR] quando aceiteManual é true e lançar pedido manual (pedido.app = true) deve imprimir
            if (impressaoManual || aceiteManual || usuarioNaoImpressor || printByApp) {
                return;
            }

            const data = {
                pedido,
                tipo: 'completo',
            };
            this.imprimir(data);
        },

        async imprimir({ pedido: pedidoId, tipo }) {
            if (this.configuracoes?.impressao?.servicoImpressao === 'App ZaperMenu') {
                this.sendPrintToApp(pedidoId, tipo);
                return;
            }

            this.showDialogImprimir(pedidoId, tipo);
        },

        async sendPrintToApp(pedido, tipo) {
            await this.$http.post('mobile-printing-app', { pedido, tipo })
                .then(resp => {
                    if (resp.data.type === 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }
                    this.setImpresso(pedido);
                    this.notify('Impressão enviada ao aplicativo mobile');
                }).catch(e => {
                    console.log(e);
                });

            this.setImpresso(pedido, tipo);
        },

        wsPrinter(event) {
            const data = {
                pedido: event.detail.pedidoId,
                tipo: event.detail.tipo,
            };
            this.imprimir(data);
        },

        setImpresso(id) {
            this.$http.post('pedidos/set-impresso', { id });
        },

        async checkUser() {
            if (this.$route.path === '/login' || this.usuario.id) {
                return;
            }

            try {
                const { data } = await this.$http.post('usuarios/get-auth');
                this.setUsuario(data.data);
            } catch (e) {
                console.log(e)
            }
        },

        async initializeDashboard() {
            if (this.$route.path === '/login' || this.isDashboardInitialized) {
                return;
            }

            this.loading = true;
            await this.$http.post('initialize-dashboard').then(({ data }) => {
                this.setUpDashboardData(data.data);
                this.retrieveLastCharge();
            }).finally(() => {
                this.loading = false;
            });
        },

        retrieveLastCharge() {
            this.$http.post('assinaturas/listar-ultima-fatura').then(({ data }) => {
                if (data.type === 'warning') {
                    return;
                }
                this.setUltimaFatura(data.data);
            });
        },

        setListeners() {
            this.$root.$on('loading', load => (this.setLoading(load)));
            this.$root.$on('notify', data => (this.setSnackbar(data)));
            this.$root.$on('imprimir', data => (this.imprimir(data)));
            this.$root.$confirm = this.$refs.confirm.open;

            document.addEventListener('auto-print', this.autoPrint);
            document.addEventListener('ws-order-event', this.wsOrderEvent);
            if (this.usuarioImpressao) {
                document.addEventListener('ws-printer', this.wsPrinter);
            }
        },

        removeListeners() {
            this.$root.$off('loading');
            this.$root.$off('notify');
            this.$root.$off('imprimir');

            document.removeEventListener('auto-print', this.autoPrint);
            document.removeEventListener('ws-order-event', this.wsOrderEvent);
            if (this.usuarioImpressao) {
                document.removeEventListener('ws-printer', this.wsPrinter);
            }
        },
    },
};
</script>

<style>
@media print {
    .no-print {
        display: none;
        visibility: hidden;
    }

    .v-dialog {
        display: none;
        visibility: hidden;
    }
}
</style>
